<template>
  <div class="d-none d-lg-block">
    <div class="card mb-2" style="height: 240px">
      <div class="card-boy"></div>
    </div>
  </div>
  <div class="d-lg-none">
    <div class="card mb-2" style="height: 510px">
      <div class="card-boy"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OperationStatsSkeleton",
  components: {},
  props: {
    rows: Number,
  },
  setup() {
    return {};
  },
});
</script>
