<template>
  <operation-stats-skeleton v-if="loading" />
  <error v-else-if="error" />
  <template v-else>
    <div class="card mb-2">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-hash me-3"></i
              >{{ $t("operation_stats.transaction_hash") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                :to="{
                  name: 'TxnDetailsRoute',
                  params: {
                    txn_hash: opDetails.transaction_hash,
                  },
                }"
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                >{{ opDetails.transaction_hash }}</router-link
              ><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(opDetails.transaction_hash)"
                >{{ $t("operation_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-circle me-3"></i
              >{{ $t("operation_stats.status") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                v-if="opDetails.transaction_successful"
                class="
                  badge
                  bg-success bg-opacity-25
                  text-success
                  border
                  align-self-center
                "
                >{{ $t("operation_stats.success") }}
                <i class="bi bi-check-circle-fill ms-1"></i
              ></span>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-person-square me-3"></i
              >{{ $t("operation_stats.source_account") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                :to="{
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: opDetails.source_account,
                  },
                }"
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                >{{ opDetails.source_account }}</router-link
              >
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(opDetails.source_account)"
                >{{ $t("operation_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-tag-fill me-3"></i
              >{{ $t("operation_stats.label") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                class="
                  text-decoration-none
                  font-monospace
                  fw-bold
                  text-dark
                  small
                "
              >
                {{
                  accountLabels.data[opDetails.source_account]?.label?.name ??
                  "-"
                }}
              </span>
              <i
                class="bi ms-5"
                :class="labelData(opDetails.source_account)?.icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  labelData(opDetails.source_account)?.tooltip
                "
                v-tooltip
              ></i>
              <router-link
                :to="{
                  name: 'DirectoryUpdateRoute',
                  query: {
                    account: opDetails.source_account,
                  },
                }"
                class="float-end text-decoration-none"
              >
                {{ $t("transaction_stats.update") }}
              </router-link>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-credit-card me-3"></i
              >{{ $t("operation_stats.operation_type") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                splitAndCapitalize(opDetails.type, "_")
              }}</span>
            </div>
          </li>
          <operation-info :operationDetails="opDetails" :details="true" />
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-clock me-3"></i
              >{{ $t("operation_stats.created_at") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                formatDate(opDetails.created_at) +
                " · " +
                formatDateElapsedTime(opDetails.created_at)
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDate, formatDateElapsedTime } from "@/utils/date";
import { splitAndCapitalize } from "@/utils/string.js";
import { useI18n } from "vue-i18n";
import Error from "@/components/Error.vue";
import OperationStatsSkeleton from "@/components/skeletons/stats/OperationStatsSkeleton.vue";
import OperationInfo from "@/components/operations/OperationInfo.vue";

export default defineComponent({
  name: "OperationStats",
  components: {
    Error,
    OperationStatsSkeleton,
    OperationInfo,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const opDetails = computed(() => store.getters["operations/getSelectedOp"]);
    const loading = computed(
      () => store.getters["operations/getLoadingStatus"]
    );
    const error = computed(() => store.getters["operations/getError"]);
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const labelData = (account) => {
      if (accountLabels.value.data[account]?.label) {
        if (
          ["Scam", "Hack"].includes(
            accountLabels.value.data[account]?.label?.name
          )
        ) {
          return {
            icon: "bi-exclamation-triangle-fill text-danger",
            tooltip: t("transaction_stats.scam_tooltip", {
              type: accountLabels.value.data[
                account
              ]?.label?.name.toUpperCase(),
            }),
          };
        }

        if (accountLabels.value.data[account]?.label.verified) {
          return {
            icon: "bi-patch-check-fill text-primary",
            tooltip: t("account_summary.verified_account"),
          };
        } else {
          return {
            icon: "bi-person-circle text-dark",
            tooltip: t("account_summary.unverified_user_defined_label"),
          };
        }
      }
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return {
      formatDate,
      formatDateElapsedTime,
      loading,
      error,
      opDetails,
      copy,
      splitAndCapitalize,
      labelData,
      accountLabels,
    };
  },
});
</script>
