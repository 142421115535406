<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <operation-stats />
  <template v-for="(eff, idx) in opDetails.effects" :key="idx">
    <effect-info :effectDetails="eff" :idx="idx" />
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Search from "@/components/Search.vue";
import OperationStats from "@/components/stats/OperationStats.vue";
import EffectInfo from "@/components/effects/EffectInfo.vue";
export default defineComponent({
  name: "OperationDetails",
  components: { Search, OperationStats, EffectInfo },
  setup() {
    const store = useStore();
    const route = useRoute();

    const opDetails = computed(() => store.getters["operations/getSelectedOp"]);

    store.dispatch("operations/fetchOpById", route.params.op_id);
    return { opDetails };
  },
});
</script>
